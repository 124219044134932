import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { iconColor, primaryColor, headlineColor } from 'helpers/themehelpers';
import { colors, borderRadiuses, fontSizes } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import { layerable } from 'helpers/traits';

import Icon from './icon';

/** Accordion-Wrapper */
const Wrapper = styled.div`
    background: ${colors.white};
    border-radius: ${borderRadiuses.s};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.85em;
    padding: 0.6em 0.95em;
    ${layerable(1)};

    ${({ layer }) =>
        layer === 0 &&
        `
        border: 1px solid ${colors.gray300};
    `};

    &:last-child {
        margin-bottom: 0;
    }
`;

/** Wrapper um Titel und Optionalen Subtitle */
const TitleWrapper = styled.div``;

/** Sections-Titel */
const Title = styled.div`
    -webkit-appearance: none !important;
    ${fontSizes.m};
    align-items: center;
    color: ${headlineColor};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
`;

/** Optionaler Subtitle */
const Subtitle = styled.span`
    display: block;
    font-size: 0.75em;
    color: ${colors.black};
`;

/* Wrapper um Icon herum */
const IconWrapper = styled.div`
    align-items: center;
    background-color: ${primaryColor};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    flex: 0 0 auto;
    height: ${rem(25)};
    justify-content: center;
    margin-left: 0.25em;
    width: ${rem(25)};

    ${mq.large`
        width: ${rem(32)};
        height: ${rem(32)};
    `};
`;

/** Angepasster Icon */
const StyledIcon = styled(Icon)`
    color: ${iconColor};
`;

/** Content */
const Content = styled.div`
    border-top: 1px solid ${colors.gray300};
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    margin-top: 1em;
    padding: 1.25em 0 1em;
`;

/**
 * Erzeugt eine Accordion-Sektion, die den Titel & eigentlichen Inhalt enthält
 * @param {string} props.children Content des Accordion-Items
 * @param {string} props.title Label / Titel des Accordion Items
 * @param {string} props.subtitel Optional: Label / Titel des Accordion Items
 * @param {boolen} props.isOpen Öffnungszustand
 * @param {func} props.onClick Klick-Handler
 * @param {ref} props.ref Ref für Scrolling
 */
const AccordionSection = React.forwardRef(
    ({ isOpen, title, subtitle, children, onClick, layer }, ref) => (
        <Wrapper ref={ref} layer={layer}>
            <Title onClick={onClick} aria-label={title} aria-expanded={isOpen} type="button">
                {subtitle ? (
                    <TitleWrapper>
                        <strong>{title}</strong>
                        <Subtitle>{subtitle}</Subtitle>
                    </TitleWrapper>
                ) : (
                    <strong>{title}</strong>
                )}
                <IconWrapper>
                    <StyledIcon type="angleDown" rotate={isOpen ? '180deg' : null} />
                </IconWrapper>
            </Title>
            <Content isOpen={isOpen}>{children}</Content>
        </Wrapper>
    )
);

AccordionSection.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    subtitle: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    layer: layerable.propType,
};

AccordionSection.defaultProps = {
    subtitle: null,
    layer: null,
};

export default AccordionSection;
