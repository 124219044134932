import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import Intro from 'ui-kit/intro';
import Image from 'ui-kit/image';
import Container from 'ui-kit/container';
import { Headline, P, Highlight } from 'ui-kit/typography';
import StickyNav from 'ui-kit/sticky-nav';
import Observable from 'ui-kit/observable';
import List from 'ui-kit/list';
import Accordion from 'ui-kit/accordion';
import Timeline from 'ui-kit/timeline';

import Layout from '../components/layout';

const CompanyPage = ({ data }) => {
    const stageImage = data.stageImage.childImageSharp;
    const contentImageThatsUs = data.contentImageThatsUs.childImageSharp;
    const historyPanoramaImage = data.historyPanoramaImage.childImageSharp;
    const productionFacilities = data.productionFacilities.edges;

    // Inhalte für Accordion mit den Geschichte der einzelnen Standort
    const accordionItems = productionFacilities
        .map(({ node }) => node)
        .map(productionFacility => ({
            title: productionFacility.altTitle,
            content: <Timeline milestones={productionFacility.milestones} />,
        }));

    return (
        <Layout description="Unternehmen">
            <Stage
                image={stageImage}
                imageAlt="LkW der Südbayerischen-Fleischwaren an einer Laderampe"
                tag="Unternehmen"
                headline={{ text: 'Wo Gutes von daheim entsteht', level: 'h1' }}
                bottomShade
            />
            <Section container="l">
                <Intro
                    title={
                        <span
                            /* eslint-disable-next-line */
                            dangerouslySetInnerHTML={{
                                __html:
                                    'Südbayerische Fleischwaren GmbH – <br /> mit Leidenschaft für mehr Genuss',
                            }}
                        />
                    }
                >
                    Die Südbayerische Fleischwaren GmbH hat ihren Hauptsitz in Ingolstadt und
                    betreibt drei modernste Fleischwerke in Traunstein, Obertraubling und
                    Ingolstadt. Das Unternehmen ging aus einer Fusion der Donauland-Fleischwerke mit
                    den Chiemgauer Fleisch- und Wurstwaren hervor, ist eine 100-prozentige
                    Tochtergesellschaft der EDEKA Südbayern und beschäftigt rund 460 Mitarbeiter –
                    darunter viele qualifizierte, hervorragend ausgebildete Fachkräfte.
                </Intro>
            </Section>

            <StickyNav
                items={[
                    {
                        id: 'das-sind-wir',
                        text: 'Das sind wir',
                        to: '#das-sind-wir',
                    },
                    {
                        id: 'standorte',
                        text: 'Standorte',
                        to: '#standorte',
                    },
                    {
                        id: 'geschichte',
                        text: 'Geschichte',
                        to: '#geschichte',
                    },
                ]}
            >
                {({ setActivePageFragment }) => (
                    <>
                        <Section bg="gray" id="das-sind-wir">
                            <Observable callback={() => setActivePageFragment('das-sind-wir')}>
                                <Container size="l" grid layout="6-6" cols="2" alignItems="start">
                                    <Image
                                        {...contentImageThatsUs}
                                        layer={1}
                                        gap={{ small: 'xxl', medium: 'none' }}
                                        alt="Mitarbeiter der Südbayerischen Fleischwaren in Mannschaftsaufstellung"
                                    />
                                    <div>
                                        <Headline level="h3">
                                            Verlässlicher Partner, breites Produktsortiment und
                                            beste Qualität
                                        </Headline>
                                        <P>
                                            Wir produzieren zahlreiche regionale Fleisch- und etwa
                                            250 Wurstsorten – aus qualitativ hochwertigen Rohstoffen
                                            von ausgewählten Lieferanten. Das Fleisch stammt fast
                                            ausschließlich von Bauern aus der Region. Wir können
                                            neben der genauen Herkunft der Tiere auch jeden
                                            einzelnen Produktionsschritt, bis hin zum fertig
                                            verpackten Produkt, genau zurückverfolgen.
                                        </P>
                                        <P>
                                            Ergänzt wird unser Angebot durch über 800 weitere
                                            Spezialitäten regionaler Metzgereien und namhafter
                                            deutscher und internationaler Markenhersteller.
                                        </P>
                                        <P>
                                            Wir liefern unsere Produkte an die Bedienungstheken und
                                            SB-Kühlregale ausgewählter EDEKA Märkte sowie an
                                            Metzgereien, Gaststätten und Kantinen – täglich frisch
                                            und unter strengster Einhaltung der Kühlkette.
                                        </P>
                                    </div>
                                </Container>
                            </Observable>
                        </Section>
                        <Section id="standorte">
                            <Observable callback={() => setActivePageFragment('standorte')}>
                                <Intro title="Bestens ausgestattet – nah am Markt" gap="xxxl">
                                    Um die hervorragende Qualität und Frische unserer Produkte
                                    sicherzustellen legen wir großen Wert auf regionale Nähe zu
                                    unseren Kunden und Lieferanten. Deshalb produzierten wir an drei
                                    spezialisierten Standorten – verteilt über das südliche Bayern.
                                    Unsere modern ausgestatteten Fleischwerke gewährleisten kurze
                                    Transportwege ohne Zeit- und Qualitätsverluste, bieten
                                    hervorragende Produktions- und Arbeitsbedingungen und
                                    garantieren einen ressourcen- und umweltschonenden Betrieb.
                                </Intro>
                                {productionFacilities.map(({ node: productionFacility }, index) => {
                                    const image = (
                                        <Image
                                            {...productionFacility.image.childImageSharp}
                                            layer={1}
                                            alt={`Firmengebäude der Niederlassung ${productionFacility.title}`}
                                            gap={{ small: 'xxl', medium: 'none' }}
                                        />
                                    );
                                    const text = (
                                        <div>
                                            <List
                                                title={productionFacility.title}
                                                items={[
                                                    <React.Fragment
                                                        key={`${productionFacility.title}-text-1`}
                                                    >
                                                        <Highlight>
                                                            Eröffnung / Erweiterung:
                                                        </Highlight>
                                                        <br />
                                                        {productionFacility.openingAndExtension}
                                                    </React.Fragment>,
                                                    <React.Fragment
                                                        key={`${productionFacility.title}-text-2`}
                                                    >
                                                        <Highlight>Betriebsfläche:</Highlight>
                                                        <br />
                                                        {productionFacility.areaSize}
                                                    </React.Fragment>,
                                                    <React.Fragment
                                                        key={`${productionFacility.title}-text-3`}
                                                    >
                                                        <Highlight>
                                                            Produktionsschwerpunkt:
                                                        </Highlight>
                                                        <br />
                                                        {productionFacility.productionFocus}
                                                    </React.Fragment>,
                                                    <React.Fragment
                                                        key={`${productionFacility.title}-text-4`}
                                                    >
                                                        <Highlight>
                                                            Mengenvolumen täglich:
                                                        </Highlight>
                                                        <br />
                                                        {productionFacility.quantityVolume}
                                                    </React.Fragment>,
                                                    <React.Fragment
                                                        key={`${productionFacility.title}-text-5`}
                                                    >
                                                        <Highlight>Mitarbeiter:</Highlight>
                                                        <br />
                                                        {productionFacility.employees}
                                                    </React.Fragment>,
                                                ]}
                                            />
                                        </div>
                                    );
                                    return (
                                        <Container
                                            key={productionFacility.title}
                                            size="l"
                                            grid
                                            layout="6-6"
                                            cols="2"
                                            alignItems="center"
                                            reverseOnMobile={index % 2}
                                            gap={
                                                index !== productionFacilities.length - 1
                                                    ? `xxxxl`
                                                    : null
                                            }
                                        >
                                            {index % 2 ? (
                                                <>
                                                    {text} {image}
                                                </>
                                            ) : (
                                                <>
                                                    {image} {text}
                                                </>
                                            )}
                                        </Container>
                                    );
                                })}
                            </Observable>
                        </Section>
                        <Section id="geschichte" container="l" bg="gray">
                            <Observable callback={() => setActivePageFragment('geschichte')}>
                                <Intro
                                    title="Tradition trifft Fortschritt und kontinuierliche Weiterentwicklung "
                                    gap="xxxl"
                                >
                                    <Image
                                        {...historyPanoramaImage}
                                        layer={1}
                                        gap="l"
                                        alt="Fotoalbum mit Bilder aus unterschiedlichen Epochen der SBF-Geschichte"
                                    />
                                    <P fontSize="m">
                                        Die Südbayerische Fleischwaren GmbH entstand im Oktober 2004
                                        im Rahmen der Fusion der EDEKA-Genossenschaften Gaimersheim
                                        und Trostberg zur EDEKA Handelsgesellschaft Südbayern mbH.
                                        Dabei ging die Südbayerische Fleischwaren GmbH aus der
                                        Verschmelzung der Donauland Fleischwerk Ingolstadt GmbH
                                        (Standorte: Ingolstadt, Obertraubling) und der Chiemgauer
                                        Fleischwaren GmbH (Standort Traunstein) hervor.
                                    </P>
                                    <P fontSize="m">
                                        An diesen drei Standorten kombinieren wir seitdem altes
                                        Wissen und traditionelles Metzgerhandwerk mit neuen
                                        Technologien und produzieren „Gutes von daheim“:
                                        ausgezeichnete Fleisch- und Wurstspezialitäten große
                                        Vielfalt – transparent und nachhaltig, in bester, streng
                                        geprüfter Qualität.
                                    </P>
                                </Intro>
                                <Accordion items={accordionItems} />
                            </Observable>
                        </Section>
                    </>
                )}
            </StickyNav>
        </Layout>
    );
};

CompanyPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        contentImageThatsUs: PropTypes.object,
        historyPanoramaImage: PropTypes.object,
        productionFacilities: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "company-page/buehne-unternehmen.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        historyPanoramaImage: file(relativePath: { eq: "company-page/panorama-geschichte.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 850, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImageThatsUs: file(
            relativePath: { eq: "company-page/inhaltsbild-das-sind-wir.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 605, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        productionFacilities: allProductionFacilitiesJson {
            edges {
                node {
                    title
                    altTitle
                    image {
                        childImageSharp {
                            fluid(maxWidth: 605, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    openingAndExtension
                    areaSize
                    quantityVolume
                    productionFocus
                    employees
                    milestones {
                        year
                        description
                    }
                }
            }
        }
    }
`;

export default CompanyPage;
