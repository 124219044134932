import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Link } from 'gatsby';
import { colors } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import { hoverAndActiveColor } from 'helpers/themehelpers';
import { gapable, layerable, roundable } from 'helpers/traits';

/**
 * Erzeugt eine Navigationsbar (innerhalb des Contents)
 * @param {string} props.children Der Inhalt des Elements
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {bool} props.rounded Optional: Ecken abgerundet oder gerade
 *
 * @example <Navbar gap="l" layer={2} rounded>Test</Navbar>
 */
const Navbar = styled.div`
    align-items: stretch;
    background-color: ${colors.white};
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${rem(50)};
    text-align: center;
    ${gapable()};
    ${layerable(1)};
    ${({ rounded }) => (rounded ? roundable('s') : null)};

    ${mq.medium`flex-direction: row;`};
`;

Navbar.propTypes = {
    children: PropTypes.node.isRequired,
    gap: gapable.propType,
    layer: layerable.propType,
    rounded: PropTypes.bool,
};

Navbar.defaultProps = {
    gap: null,
    layer: null,
    rounded: true,
};

/**
 * Navbar Item
 */
Navbar.Item = styled.div`
    align-items: center;
    align-content: center;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: no-wrap;

    justify-content: center;
    line-height: 1.4;
    overflow: hidden;
    padding: 0.25em 0.5em;
    width: auto;

    ${mq.medium`
        padding: 0 1em;
    `};

    ${({ largeOnly }) =>
        largeOnly
            ? css`
                  display: none;
                  ${mq.large`display: flex;`};
              `
            : null};

    ${({ border }) =>
        border
            ? css`
                  &:not(:last-child) {
                      ${mq.medium`
                            border-bottom: none;
                            border-right: 1px solid ${colors.gray300};
                        `};
                  }
              `
            : null};
`;

Navbar.Item.propTypes = {
    children: PropTypes.node.isRequired,
    border: PropTypes.bool,
    largeOnly: PropTypes.bool,
};

Navbar.Item.defaultProps = {
    border: false,
    largeOnly: false,
};

/**
 * Navbar Link
 */
Navbar.Link = styled(Link)`
    align-items: center;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent;
    color: inherit;
    cursor: pointer;
    display: flex;
    height: calc(100%);
    padding-top: 3px;
    text-decoration: none;

    ${mq.medium`
        padding-top: 2px;
        border-bottom: 2px solid transparent;
    `};

    ${mq.large`
        padding-top: 2px;
        border-bottom: 3px solid transparent;
    `};

    ${({ isActive }) =>
        isActive
            ? css`
                  color: ${hoverAndActiveColor};
                  border-bottom: 1px solid ${hoverAndActiveColor};
                  text-decoration: none;
                  ${mq.medium`
                        border-bottom: 2px solid ${hoverAndActiveColor};
                  `};
                  ${mq.large`
                        border-bottom: 3px solid ${hoverAndActiveColor};
                  `};
              `
            : null};
`;

Navbar.Link.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool,
};

Navbar.Link.defaultProps = {
    isActive: false,
};

export default Navbar;
