import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { primaryColor, buttonTextColor } from 'helpers/themehelpers';
import { mq } from 'helpers/stylehelpers';
import { gapable, roundable } from 'helpers/traits';
import { colors, fontSizes } from 'helpers/variables';

/** Wrapper */
const Wrapper = styled.div`
    ${gapable()};
    position: relative;
`;

/** Milestones */
const Milestones = styled.ul`
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;

    &::before {
        background: ${colors.gray300};
        content: '';
        height: 100%;
        left: ${rem(15)};
        position: absolute;
        top: 0;
        width: ${rem(1)};

        ${mq.medium`
            left: ${rem(30)};
        `};
    }
`;

/** Date */
const Date = styled.span`
    ${fontSizes.s};
    display: block;
    background: ${primaryColor};
    color: ${buttonTextColor};
    margin-bottom: 0.85em;
    padding: 0.35em 0.5em;
    line-height: 1.2;
    position: relative;
    text-align: center;
    ${roundable('s')};

    > div {
        padding-bottom: 0.25em;
    }

    ${mq.medium`
        margin-bottom: 0;
    `};
`;

/** Content */
const Content = styled.p`
    margin: 0;
    padding-left: ${rem(35)};
    width: 75%;

    ${mq.medium`
        padding-left: ${rem(20)};
        border: none;
    `};
`;

/** Item */
const Item = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.25em;
    overflow: hidden;
    padding-bottom: 0;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    ${mq.medium`
        align-items: first baseline;
        flex-direction: row;
        width: 100%;
    `};
`;

/**
 * Erzeugt eine Timeine
 *
 * @param {array} props.milestones Die Meilensteine als Array aus Objects mit "year" und "description"
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.className Optional: Klasse für erweitertes Styling
 *
 * @example <Timeline milestones={[{year: "1990", description: "Foo"}]} />
 *
 */
const Timeline = ({ milestones, className, gap }) => (
    <Wrapper className={className} gap={gap}>
        <Milestones>
            {milestones.map(item => (
                <Item key={item.year}>
                    <Date
                        dangerouslySetInnerHTML={{
                            __html: item.year.replace('-', '<div>-</div>'),
                        }}
                    />
                    <Content>{item.description}</Content>
                </Item>
            ))}
        </Milestones>
    </Wrapper>
);

/** Props */
Timeline.propTypes = {
    className: PropTypes.string,
    gap: gapable.propType,
    milestones: PropTypes.arrayOf(
        PropTypes.shape({
            year: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        })
    ).isRequired,
};

Timeline.defaultProps = {
    className: null,
    gap: null,
};

export default Timeline;
