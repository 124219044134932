import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { gapable } from 'helpers/traits';

import { Headline } from './typography';

/** Wrapper */
const Wrapper = styled.div`
    ${gapable()};
`;

/** List */
const ListItems = styled.ul`
    ${({ showBullets }) =>
        showBullets
            ? 'list-style-type: disc; padding: 0 0 0 1.5em;'
            : 'list-style-type: none; padding: 0;'};
    margin: 0;
`;

/** Item */
const Item = styled.li`
    padding-bottom: ${rem(8)};
`;

/**
 * Erzeugt eine Liste
 *
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.items Optional: die Liste Punkte
 * @param {string} props.title Optional: der Titel
 * @param {bool} props.showBullets Optional: Flag, ob Liste Bullets haben soll
 *
 * @example <List items={listitems} title="title"  />
 */
const List = ({ title, items, showBullets, gap }) => (
    <Wrapper gap={gap}>
        {title && (
            <Headline level="h3" textAlign="left">
                {title}
            </Headline>
        )}
        <ListItems showBullets={showBullets}>
            {items.map(item => (
                <Item key={typeof item === 'string' ? item : item.key}>{item}</Item>
            ))}
        </ListItems>
    </Wrapper>
);

/** Props */
List.propTypes = {
    gap: gapable.propType,
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    title: PropTypes.string,
    showBullets: PropTypes.bool,
};

/** default Props */
List.defaultProps = {
    gap: null,
    title: null,
    showBullets: false,
};

export default List;
